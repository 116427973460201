import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import Youtube from "components/youtube";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "storage/ssd-lifespan"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`SSDの寿命は5年、5年で4％程度の故障率`}</h1>
    <Socials {...props} mdxType="Socials" />
    <p>{`HDDに代わるストレージとして主流になりつつあるSSD(Solid State Drive)。HDDと比較すると物理的衝撃に強いかつ長寿命と言われている。しかしながらバックアップを取らなくてよいほど耐用年数が長いわけではなく、また故障時にはデータ復旧が難しいという難点も持つ。`}</p>
    <p>{`本ページではSSDの寿命について説明し、データを守るための注意点や工夫について述べる。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "SSDの寿命とよくある誤解",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#SSD%E3%81%AE%E5%AF%BF%E5%91%BD%E3%81%A8%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%AA%A4%E8%A7%A3",
        "aria-label": "SSDの寿命とよくある誤解 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDの寿命とよくある誤解`}</h2>
    <p>{`SSDの寿命は5年とタイトルに書いたが、この定義をはっきりさせておく。まず前提として一般的なユーザがパソコンを長時間使用して、どれぐらいの人のSSDが壊れたかというようなデータは存在しない。よって寿命は推測となる。`}</p>
    <p>{`質の高いアンケートではないものの、推測で根拠となりそうな記事が`}<a parentName="p" {...{
        "href": "https://net-shitsuji.jp/pc/content/breakdown-ranking.html",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`メーカー別パソコン故障率ランキング`}</a>{`である。`}</p>
    <p>{`この記事では5年間以内にパソコンを購入した3万人を対象にその故障率を問うている。5年以内での購入なので、平均的には2.5年程度使用している計算となる。`}</p>
    <p>{`これによるとパソコンの5年以内の故障率はおおよそ20％であり、そのうちデータ破損が原因のものは約5％であった。使用中に故障したというが30%程度あり、そのうち多めに見積もって半分の15%がストレージが原因だとすると、20％のうちの20％、すなわち`}<strong parentName="p">{`2.5年に4％のストレージが故障した`}</strong>{`と推測できる。`}</p>
    <p>{`テストが実施された時は2016年であり、ほとんどSSDは普及していなかったと考えられる。これに加えてHDDの一般的な保証期間は2～3年のものが多く、SSDは5年保証のものが多い。`}</p>
    <p>{`同じメーカーで2.5年保証と5年保証の製品があるとして、そのそれぞれの2．5年、5年での故障率はほぼ同じはずである。`}</p>
    <p>{`こう考えると`}<strong parentName="p">{`SSDは5年で4％の確率で何かしらの不具合が起きる`}</strong>{`と推測できる。ざっくりだが大体正しいと思われる。`}</p>
    <p>{`ところでネットをパラパラみているとMTBFやTBWという評価値を寿命としている例があるので誤解を正しておく。`}</p>
    <h3 {...{
      "id": "MTBFが100万時間なので100年以上持つは大きな誤解",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#MTBF%E3%81%8C100%E4%B8%87%E6%99%82%E9%96%93%E3%81%AA%E3%81%AE%E3%81%A7100%E5%B9%B4%E4%BB%A5%E4%B8%8A%E6%8C%81%E3%81%A4%E3%81%AF%E5%A4%A7%E3%81%8D%E3%81%AA%E8%AA%A4%E8%A7%A3",
        "aria-label": "MTBFが100万時間なので100年以上持つは大きな誤解 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MTBFが100万時間なので100年以上持つは大きな誤解`}</h3>
    <p>{`SSDの耐久性を図る尺度としてMTBFという指標が用いられている。MTBFとはMean Time Between Failureの略で平均故障間隔ともいう。基本的にはある製品が永続的に使用され続け、故障しては修理されを繰り返したと仮定して、修理から故障までの平均期間のことである。`}</p>
    <p>{`定義は曖昧で、SSDの場合は作成されて間もない複数のSSDを一定期間使用して、その故障率から計算しているのであろう。`}</p>
    <p>{`間違った考えはMTBFが100万時間だとしたら1年が8760時間なので寿命は100年以上とする考え方である。`}</p>
    <p>{`人間を例えに考えよう。1000人生まれてきた人のうち1人だけ1歳までに死亡するとする。この時全体で1000年生き、1人が死亡したのでMTBFは1000年である。ところがご存じの通り人間の寿命は80年足らずである。`}</p>
    <p>{`つまり`}<strong parentName="p">{`MTBFは単に故障率を表しているのであって、寿命を表しているわけではない`}</strong>{`。間違えないように注意しよう。`}</p>
    <p>{`また80歳の人をサンプルとしてMTBFをはかると10年程度になるだろうし、条件によって大きく結果が異なってくる可能性が高い。メーカーによって条件が異なってくるのが普通なので、`}<strong parentName="p">{`MTBFの比較はメーカー間でなくメーカー内の製品で行うべき`}</strong>{`である。`}</p>
    <h3 {...{
      "id": "TBWはハードな読み書きする時のみ有効な指標",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#TBW%E3%81%AF%E3%83%8F%E3%83%BC%E3%83%89%E3%81%AA%E8%AA%AD%E3%81%BF%E6%9B%B8%E3%81%8D%E3%81%99%E3%82%8B%E6%99%82%E3%81%AE%E3%81%BF%E6%9C%89%E5%8A%B9%E3%81%AA%E6%8C%87%E6%A8%99",
        "aria-label": "TBWはハードな読み書きする時のみ有効な指標 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TBWはハードな読み書きする時のみ有効な指標`}</h3>
    <p>{`SSDの寿命の指標として、TBWというものがある。Tera Bytes Writtenの略で、どれだけの書き込み量をSSDが保証するかを表した指標である。`}</p>
    <p>{`例えばサムスンの860EVOの1TBSSDは300TBWである。これはたとえば、容量が不足したため不要なファイルを500GBまで削除することを600回行って到達できる水準である。`}</p>
    <p>{`ただ、パソコンの内部処理としてSSDへの書き込みを行っている。分かりやすい例としてはブラウザのキャッシュがある。これは一度開いたサイトが再度開かれたときに、素早く起動できるようSSDにインターネットから取得されたファイル情報を保存しておく機能である。`}</p>
    <p>{`この例は大きな書き込みインパクトは無いが、1つSSDへの書き込みが多い例がある。それは仮想記憶である。`}</p>
    <p>{`仮想記憶とはメモリに収まりきらない情報を、一時的にSSDやHDDなどのストレージに書き込むことをいう。SSDの速度はメモリと比べて遅いため、仮想記憶へのアクセスが多くなるとアクセスが遅く感じることがある。しかし普通に1日10時間程度使っている場合でも平均して10GBぐらいの使用となることが多いので、300TBWだと3万日で80年は持つ。`}</p>
    <p>{`ただ、`}<strong parentName="p">{`慢性的にメモリの使用率が100%近くになっている場合にはパソコンは重く感じ、仮想記憶へのアクセスが頻発することから、SSDを保護する観点でもよろしくない`}</strong>{`。メモリの増設を検討するべきである。`}</p>
    <p>{`話がそれたが、企業のデータセンターなどならともかくとして、`}<strong parentName="p">{`一般的な使用でTBWが寿命を決める要因となることはほぼ無い`}</strong>{`のでTBWがどうだからという議論は的が外れているといえる。`}</p>
    <p>{`SSDへの総書き込み量が気になる場合は`}<a parentName="p" {...{
        "href": "https://forest.watch.impress.co.jp/library/software/crdiskinfo/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`CrystalDiskInfo`}</a>{`を使用すると良い、メーカーによっては十分なデータが取れない場合があるが、SSDへの総書き込み量と使用時間を教えてくれる。何となく不安を感じた場合は試してみよう。`}</p>
    <h2 {...{
      "id": "SSDは長期用保存媒体として向かない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#SSD%E3%81%AF%E9%95%B7%E6%9C%9F%E7%94%A8%E4%BF%9D%E5%AD%98%E5%AA%92%E4%BD%93%E3%81%A8%E3%81%97%E3%81%A6%E5%90%91%E3%81%8B%E3%81%AA%E3%81%84",
        "aria-label": "SSDは長期用保存媒体として向かない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDは長期用保存媒体として向かない`}</h2>
    <p>{`SSDが搭載されたパソコンを放置して数年寝かせ、その後取り出したときにデータが残っているのか？あるいは保存用ストレージとしてSSDは有用であるかを問われると答えはNoである。`}</p>
    <p>{`今度はSSDの寿命を素子・素材の観点から確認する。`}</p>
    <h3 {...{
      "id": "長期保存用としてNAND型フラッシュメモリは向かない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%95%B7%E6%9C%9F%E4%BF%9D%E5%AD%98%E7%94%A8%E3%81%A8%E3%81%97%E3%81%A6NAND%E5%9E%8B%E3%83%95%E3%83%A9%E3%83%83%E3%82%B7%E3%83%A5%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AF%E5%90%91%E3%81%8B%E3%81%AA%E3%81%84",
        "aria-label": "長期保存用としてNAND型フラッシュメモリは向かない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`長期保存用としてNAND型フラッシュメモリは向かない`}</h3>
    <p>{`SSDやUSBメモリは構造上NAND型フラッシュメモリという種別に分類される。データをセルという半導体素子に記憶させるのだが、セルに一定以上電荷が溜まった状態を1、電荷が放電された状態を0というように判別する。このセルに蓄えられる電荷であるが、10年もたつと経年劣化が進み、自然放電によるデータ欠損の問題が発生する可能性がある。データの自然消失などともいわれる。`}</p>
    <p>{`この自然放電が起こると1の状態が0に書き換わるため、ファイルを開くと文字化けが起きている場合や、悪い場合はOSごと起動しなくなる可能性もある。`}</p>
    <p>{`USBではデータの保持期間として5年から10年が一般的であるため、SSDに関しても`}<strong parentName="p">{`5年程度放置しているとデータの自然放電によりデータが欠損してしまう可能性がある。`}</strong></p>
    <p>{`ちなみにUSBメモリは大事なデータを守るために1年に1度はパソコンにさして通電させましょうと通説もあるが、嘘である。`}</p>
    <p>{`メモリ(RAM)の場合は定期的に電荷を保持するためのリフレッシュという操作が走るものの、フラッシュメモリに関しては`}<strong parentName="p">{`通電したからと言ってセルに溜まる電荷が増えるわけではない`}</strong>{`。`}</p>
    <p>{`SSDやUSBメモリの場合はあるセルの内容が書き換えられたときに再び電荷が充填されるのである。このため`}<strong parentName="p">{`USBメモリ内の大切なデータを守ろうとするならば、一度パソコンにデータを移し、再び戻す`}</strong>{`作業をする必要がある。`}</p>
    <p>{`話がそれたが結論としてNAND型フラッシュメモリである`}<strong parentName="p">{`SSDは長期保存用としては心もとない`}</strong>{`ため。`}<strong parentName="p">{`耐用年数が10-30年近くあるBDやDVD`}</strong>{`で保存するか、より良い例としては`}<strong parentName="p">{`クラウドストレージを契約して使う`}</strong>{`ことがデータを守る上では重要な選択肢となる。`}</p>
    <p>{`ちなみにすぐにデータが消えてしまうという論説を目にするが、1年足らずで自然放電によるデータ欠陥が発生してしまうほど脆弱なものではなく、半導体協会(JEDEC)によるSSDの要求仕様によると、40度のパソコン内温度で毎日8時間使用し、室温が30度であった場合に1年間のデータ欠損率はおよそ100TB中の1bitに抑えられることがテストをパスする条件とされている。(`}<a parentName="p" {...{
        "href": "https://www.jedec.org/sites/default/files/Alvin_Cox%20%5BCompatibility%20Mode%5D_0.pdf",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`JEDECプレゼン資料へのリンク（PDF)`}</a>{`)参考程度に`}</p>
    <h3 {...{
      "id": "故障したときのデータ復旧が難しい",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%95%85%E9%9A%9C%E3%81%97%E3%81%9F%E3%81%A8%E3%81%8D%E3%81%AE%E3%83%87%E3%83%BC%E3%82%BF%E5%BE%A9%E6%97%A7%E3%81%8C%E9%9B%A3%E3%81%97%E3%81%84",
        "aria-label": "故障したときのデータ復旧が難しい permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`故障したときのデータ復旧が難しい`}</h3>
    <p>{`HDDと比べると、故障したときの復旧が非常に難しいのがSSDの特徴であり、また何の前触れもなく故障するパターンも多い。これには理由の2つがある。`}</p>
    <p>{`1つはHDDが壊れた時は物理的な損傷により壊れる事が多いためである。ハードウェアは内部で磁気ヘッドと呼ばれる細長い機器がプラッタと呼ばれる円盤にデータを記録していく方式であり、この磁気ヘッドの不具合で壊れる可能性が高かった。`}</p>
    <p>{`修復方法としてはハードウェア的な修正で済むため、内部のデータは守られている可能性が高い。また磁気ヘッドが奇妙な音を立てることが多いため故障の前触れとしても分かりやすい。`}</p>
    <p>{`一方でSSDはハードウェア的な作りによる故障が分かりにくいため復旧作業は難しくなる。`}</p>
    <p>{`2つ目として、`}<strong parentName="p">{`HDDの記憶は連続的であるが、SSDの記憶は断片的`}</strong>{`という理由がある。`}</p>
    <p>{`HDDの場合は円盤に磁気データが残っていたとして、円心に沿う形でデータを取り出せば幾らかデータを復旧できる可能性が高い。1つ1つの写真や動画などは円心に沿う形でデータが記憶されているためである。`}</p>
    <p>{`一方でSSDは一つの動画や写真であっても連続的な領域には保存されておらず、断片的に保存されている。`}</p>
    <p>{`SSDには書き込み回数が存在するが、SSD内の同じ個所に何度も書き込みを行わないように書き込み場所を分散させるウェアレベリングという技術を採用しているためである。`}</p>
    <p>{`これによりSSDの寿命自体は長くなるものの、連続的な領域によるデータの復元が不可能で、データの断片を集めていく必要があり、復元の難易度が格段に上がる。`}</p>
    <p>{`よって`}<strong parentName="p">{`長期用保存には向かず、壊れた時のダメージも大きいためやはり小まめにバックアップを取っていく必要がある`}</strong>{`。`}</p>
    <h2 {...{
      "id": "SSDの寿命を長引かせる方法",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#SSD%E3%81%AE%E5%AF%BF%E5%91%BD%E3%82%92%E9%95%B7%E5%BC%95%E3%81%8B%E3%81%9B%E3%82%8B%E6%96%B9%E6%B3%95",
        "aria-label": "SSDの寿命を長引かせる方法 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDの寿命を長引かせる方法`}</h2>
    <p>{`と書いたものの長引かせるということは無理だが、SSDの寿命を無理に縮めることがないように気を付けるポイントをまとめる。`}</p>
    <h3 {...{
      "id": "メモリの容量を十分に増やしておくこと",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A1%E3%83%A2%E3%83%AA%E3%81%AE%E5%AE%B9%E9%87%8F%E3%82%92%E5%8D%81%E5%88%86%E3%81%AB%E5%A2%97%E3%82%84%E3%81%97%E3%81%A6%E3%81%8A%E3%81%8F%E3%81%93%E3%81%A8",
        "aria-label": "メモリの容量を十分に増やしておくこと permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`メモリの容量を十分に増やしておくこと`}</h3>
    <p>{`メモリの容量が多ければ、仮想記憶へのアクセスが少なくなり、書き込み量も減る。常に100%近くメモリを使用している状態では、仮想記憶へのアクセスが異常に増えてしまうはずなのでメモリを増やして対処すべきである。`}</p>
    <p>{`そもそもパソコン自体も相当重くなっているはずなので、その意味でも増設した方が良いと言える。`}</p>
    <h3 {...{
      "id": "車の中にSSD搭載のパソコンを放置しない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%BB%8A%E3%81%AE%E4%B8%AD%E3%81%ABSSD%E6%90%AD%E8%BC%89%E3%81%AE%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%92%E6%94%BE%E7%BD%AE%E3%81%97%E3%81%AA%E3%81%84",
        "aria-label": "車の中にSSD搭載のパソコンを放置しない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`車の中にSSD搭載のパソコンを放置しない`}</h3>
    <p>{`SSDは面白い熱特性を持っており、通電中は温度が低いほど、使用していないときは温度が高いほどデータが失われやすい。通電中はパソコンの周囲の温度が高くなるため問題ないが、USBメモリやSSD搭載のパソコンを夏の車の中に置きっぱなしにしないように気を付けよう。`}</p>
    <p>{`もっともスマホも含め電化製品を車の中には長時間置いとかない方が良いのは基本である。SSDも同様というわけである。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      